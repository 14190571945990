<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand flex" to="/">
        <div class="navbar-brand-logo logo-container">
          <img v-lazy="'img/astro-logo.png'" alt="" />
        </div>
        <div class="navbar-brand-text">
          Hong Kong AstroSoft Ltd
        </div>
      </router-link>
      <!-- <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">
          Please mail to contact@astrosoft.ai if you want to know more about us
        </div>
      </el-popover> -->
    </template>
    <template slot="navbar-menu">
      <drop-down
        tag="li"
        title="Astro Chat"
        icon="now-ui-icons ui-2_chat-round"
        class="nav-item"
      >
        <a
          href="https://chat.astrosoft.ai/"
          target="_blank"
          class="dropdown-item"
        >
          <i class="now-ui-icons ui-2_chat-round"></i>Start to Chat
        </a>
        <a
          href="https://chat.astrosoft.ai/privacy.html"
          target="_blank"
          class="dropdown-item"
        >
          <i class="now-ui-icons files_paper"></i>Privacy
        </a>
        <a
          href="https://chat.astrosoft.ai/terms.html"
          target="_blank"
          class="dropdown-item"
        >
          <i class="now-ui-icons files_single-copy-04"></i>Terms
        </a>
      </drop-down>
      <drop-down
        tag="li"
        title="Astro PDF"
        icon="now-ui-icons files_paper"
        class="nav-item"
      >
        <a
          href="https://file.astrosoft.ai/"
          target="_blank"
          class="dropdown-item"
        >
          <i class="now-ui-icons"><img src='img/word-icon.png'></i>PDF to Word
        </a>
      </drop-down>      
      <li class="nav-item">
        <a
          class="nav-link"
          href="mailto:contact@astrosoft.ai"
          target="_blank"
        >
          <i class="now-ui-icons ui-1_email-85"></i>
          <p>contact@astrosoft.ai</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    DropDown,
    Navbar,
    // NavLink,
    [Popover.name]: Popover
  }
};
</script>

<style scoped></style>
