<template>
  <div>
    <div
      class="page-header page-header clear-filter"
      filter-color="blue"
    >
      <parallax
        class="page-header-image"
        style="background-image:url('img/kaoshijuan_background_of_website4.png')"
      >
      </parallax>
      <div class="container">
        <!--
        <div class="content-center brand">
          <img class="n-logo" src="img/astro-logo.png" alt="" />
          <h1 class="h1-seo">AstroSoft</h1>
          <h3>Focus on User-interface and AI Development</h3>
          <h5><i class="now-ui-icons location_pin"> Kowloon, Hong Kong</i></h5>
        </div>
        <h6 class="category category-absolute">
          Hong Kong AstroSoft Ltd.
        </h6>
        <h1 class="category category-absolute"><i class="now-ui-icons arrows-1_minimal-down"></i></h1>
        -->
        <div class="block-container">
          <block-with-image
            image-pos="right"
            image-src="img/index-image-stacked.png"
          >
            <div slot="title" class="slogan">
              <div>探索無限，智啟未來</div>
              <div>Explore the Boundless, Empower the Future</div>
            </div>
          </block-with-image>
        </div>
      </div>
    </div>
    <div class="page-section">
      <div class="block-container">
        <block-with-image
          class="block-services"
          image-pos="right"
        >
          <!-- <div slot="heading">我們的服務 OUR SERVICES</div> -->
          <div slot="body">
            <div class="block-title">解決方案諮詢與提供定制開發</div>
            <div class="block-desc">在 Astrosoft AI，我們專注於為客戶提供創新且高效的解決方案諮詢與定制開發服務。
              我們深入了解您的業務需求，運用最先進的人工智能技術，量身打造符合您特定要求的解決方案。
              無論是從概念設計、系統集成，還是到最終部署與維護，我們都全程陪伴，確保每個環節都能順利進行，幫助您在競爭激烈的市場中保持領先地位。</div>
            <div class="block-title">Solution Consultation & Custom Development</div>
            <div class="block-desc">
              At Astrosoft AI, we focus on providing innovative and efficient 
              solution consulting and custom development services to our clients. 
              We deeply understand your business needs and use the most advanced 
              artificial intelligence technology to tailor solutions that meet 
              your specific requirements. 
              From conceptual design, system integration, 
              to final deployment and maintenance, 
              we are with you throughout the entire process to ensure every step goes smoothly 
              and help you stay ahead in the highly competitive market.
            </div>
          </div>
          <div slot="footer">
            <a href="https://www.freelancer.com/service/large_language_models/ai-solution-consultation-and-custom-development?frm=HKAstroSoft&sb=t" target="_blank" rel="noopener noreferrer">
              <n-button type="primary" round block size="lg">預定服務 / Booking Service</n-button>
            </a>
          </div>
          <div slot="image">
            <card>
              <div class="card-inner flex-row">
                <div class="card-image">
                  <img src="img/AI-agent-icon-2.png"/>
                </div>
                <div>
                  <div class="card-title">定制智能體  Custom AI Agent</div>
                  <div class="card-desc">構建自動化工作流程的智能 AI 代理或聊天機器人，可以服務於智能客服，AI知識庫。
                  </div>
                  <div class="card-desc">We provide intelligent AI agents or chatbots for automated workflows, which can serve intelligent customer service and AI knowledge bases.</div>
                </div>
              </div>
            </card>


            <card>
              <div class="card-inner flex-row">
                <div class="card-image">
                  <img src="img/rpa-icon.png"/>
                </div>
                <div>
                  <div class="card-title">流程自動化  RPA</div>
                  <div class="card-desc">我們提供智慧 RPA 解決方案，用於自動化資料收集、處理和報告，確保準確、及時、合規的運營，同時降低成本並提高效率。</div>
                  <div class="card-desc">We provide intelligent RPA solutions for automating data acquisition, processing, and reporting, 
                    ensuring accurate, timely, and compliant operations while reducing costs and boosting efficiency.</div>
                  
                </div>
              </div>
            </card>
            
          </div>
        </block-with-image>
      </div>
    </div>
    <div class="page-section">
      <div class="page-section-bg left">
        <img class="page-section-bg-img" v-lazy="'img/index-image-about.png'" alt="" />
      </div>
      <div class="block-container absolute">
        <block-with-image
          image-pos="left"
        >
          <!-- <div slot="heading">关于人工智能 / ABOUT AI</div> -->
          <div slot="title">大模型的多元商業應用 <br>Diverse Business Applications of Large Models</div>
          <div slot="desc">
            大模型的應用遠不止於智能體和RPA。在文本生成方面，大模型能自动生成高質量的內容，如新聞文章、廣告文案，減少人工依賴，並支持多語言創作，助力全球市場擴展。
            意圖識別通過分析客戶問題，提供準確回應，優化營銷策略，提升轉化率。
            多模態數據分析處理文本、圖像、音頻、視頻等多種數據，提供綜合分析結果，支持科學決策，同時結合用戶行為數據，實現精準個性化推薦，增強用戶體驗。
            圖片分析則識別圖像中的物體和場景，應用於安防監控、自動駕駛和醫療影像診斷，提高效率和準確性。
            <br><p/>
            Large models offer extensive business applications beyond intelligent agents and RPA. 
            For text generation, they automatically produce high-quality content such as news articles and ads, 
            reducing reliance on human writers and supporting multilingual content for global expansion. 
            Intent recognition analyzes customer queries to provide accurate responses and personalized marketing, increasing conversion rates.
            Multimodal data analysis processes text, images, audio, and video to deliver comprehensive insights, 
            enhancing decision-making and offering precise personalized recommendations. Image analysis identifies objects in photos, 
            aiding security, autonomous driving, and medical imaging, improving accuracy and efficiency.
          </div>
          <div slot="footer">
            <!-- <n-button type="primary" round block size="lg">Button</n-button> -->
          </div>
        </block-with-image>
      </div>
    </div>
    <div class="page-section">
      <div class="page-section-bg top absolute">
        <img class="page-section-bg-img" v-lazy="'img/index-image-story.png'" alt="" />
        <div class="page-section-bg-mask"></div>
      </div>
      <div class="block-container">
        <block-with-image
          class="block-story"
          image-pos="none"
        >
          <div slot="heading">OUR STORY</div>
          <div slot="title">How to get started</div>
          <div slot="body" class="block-cards flex-row">
            <card>
              <div class="card-inner flex-col">
                <div class="card-image">
                    <img src="img/lunch-icon.png"/>
                </div>
                <div class="card-title">Company Started</div>
                <n-button type="primary" round block size="lg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-hidden="true" width="24" height="24" style="margin-right: 24px;">
                    <path fill="#FFFFFF" d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"></path></svg>
                
                  Jun 2024</n-button>
              </div>
            </card>
            <card>
              <div class="card-inner flex-col">
                <div class="card-image">
                  <img src="img/shakehand-icon.png"/>
                </div>
                <div class="card-title">
                  <div>Our First Customer</div>
                </div>
                <n-button type="primary" round block size="lg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-hidden="true" width="24" height="24" style="margin-right: 24px;">
                    <path fill="#FFFFFF" d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"></path></svg>
                  Oct 2024
                </n-button>
              </div>
            </card>
            <card>
              <div class="card-inner flex-col">
                <div class="card-image">
                  <img src="img/AI-agent-icon-2.png"/>
                </div>
                <div class="card-title">
                  <div>AstroChat Service Released</div>
                </div>
                <n-button type="primary" round block size="lg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-hidden="true" width="24" height="24" style="margin-right: 24px;">
                    <path fill="#FFFFFF" d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"></path></svg>
                
                  Nov 2024</n-button>
              </div>
            </card>
            <card>
              <div class="card-inner flex-col">
                <div class="card-image">
                  <img src="img/word-icon.png"/>
                </div>
                <div class="card-title">
                  <div>AI powered Pdf to Word Service</div>
                </div>
                <n-button type="primary" round block size="lg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-hidden="true" width="24" height="24" style="margin-right: 24px;">
                    <path fill="#FFFFFF" d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"></path></svg>
                
                  Dec 2024</n-button>
              </div>
            </card>
          </div>
        </block-with-image>
      </div>
    </div>
    <div class="page-section">
      <div class="page-section-bg right">
        <img class="page-section-bg-img" v-lazy="'img/AstroSoftAI.png'" alt="" style="border-radius: 50px;"/>
      </div>
      <div class="block-container absolute">
        <block-with-image
          image-pos="right"
        >
          <!-- <div slot="heading">我們的技術 / OUR SKILL</div> -->
          <div slot="title">精通智能代理与AI工作流，提供高性能Python应用和云端部署<p/>Expert in Intelligent Agents and AI Workflows, with High-Performance Python Apps and Cloud Deployment</div>
          <div slot="desc">我們團隊精通Agent構建、AI工作流編排、RAG檢索和模型管理等先進解決方案，能夠為企業提供全方位的技術支持。
            我們擅長使用Python開發高性能的分布式伺服器端應用，確保系統的穩定性和擴展性。
            在Web開發方面，我們精通現代前端框架，能夠設計和構建直觀、響應式的用戶界面，提升用戶體驗。
            此外，我們熟悉主流雲平台（如AWS、Azure、Google Cloud），能夠高效配置和管理容器化應用，並搭建CI/CD流水線，實現自動化部署和監控，確保系統的高可用性和彈性擴展。
            <p/>
            Our team excels in building Agents, orchestrating AI workflows, implementing RAG retrieval, and managing models, 
            providing comprehensive technical support to businesses. 
            We specialize in developing high-performance, distributed server-side applications using Python, 
            ensuring system stability and scalability. In web development, we are proficient in modern front-end frameworks, 
            enabling us to design and build intuitive, responsive user interfaces that enhance user experience. Additionally, 
            we are well-versed in major cloud platforms (such as AWS, Azure, and Google Cloud), 
            allowing us to efficiently configure and manage containerized applications and set up CI/CD pipelines for automated deployment and monitoring, 
            ensuring high availability and elastic scalability.
          </div>
          <div slot="footer">
            <!-- <n-button type="primary" round block size="lg">了解更多 Know More</n-button> -->
          </div>
        </block-with-image>
      </div>
    </div>
    <!--
    <div class="section section-about-us">
      <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">Who we are?</h2>
              <h5 class="description">
                We are a group of programmers who had been working for China internet giants. 
                We all have over 10-year experience on front-end and back-end development. 
                Today, large language models have become mainstream, and many companies and
                 organizations hope to use Gen-AI to improve their customer 
                 management capabilities and improve work efficiency. We are able to 
                 provide services to customers based on open source and commercial large 
                 language models to meet our customs' need.
              </h5>
            </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('img/kaoshijuan_background_of_website.png')"
              >
                <p class="blockquote blockquote-primary">
                  "By leveraging AI as a collaborative brainstorming tool, 
                  designers can expedite and diversify the idea generation 
                  and iteration process."
                  <br />
                  <br />
                  <small>- XUAN</small>
                </p>
              </div>
              <div
                class="image-container"
                style="background-image: url('img/dify-demo.png')"
              ></div>
            </div>
            <div class="col-md-5">
              <div
                class="image-container image-right"
                style="background-image: url('img/screenshot-code.png')"
              ></div>
              <h3>
                What can we do for you?
              </h3>
              <p>
                If you need a website built, we can help you select a style 
                from thousands of templates that you'll love. We'll customize 
                it to meet your needs, ensuring it's responsive and compatible 
                with devices like PCs and mobile. We can also use AI to create 
                all the images, icons, and logos, with full copyright ownership 
                transferred to you. Additionally, we'll handle the web acceleration 
                deployment so that your site loads quickly. This way, you can focus 
                on what matters most—your business and market opportunities.
              </p>
              <p>
                If you're interested in optimizing your business processes through 
                artificial intelligence, we can engage with you to provide AI-native 
                solutions based on large language models. We'll work with you to set up 
                and deploy the relevant systems, helping you reduce costs and increase 
                efficiency in the competitive business landscape.
              </p>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  -->
  </div>
</template>
<script>
import { Parallax, BlockWithImage, Button, Card } from '@/components';
import BasicElements from './components/BasicElementsSection';
import Navigation from './components/Navigation';
import TabsSection from './components/Tabs';
import ProgressPagination from './components/ProgressPagination';
import Notifications from './components/Notifications';
import Typography from './components/Typography';
import JavascriptComponents from './components/JavascriptComponents';
import CarouselSection from './components/CarouselSection';
import NucleoIconsSection from './components/NucleoIconsSection';
import SignupForm from './components/SignupForm';
import ExamplesSection from './components/ExamplesSection';
import DownloadSection from './components/DownloadSection';

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Parallax,
    BlockWithImage,
    [Button.name]: Button,
    Card
  }
};
</script>
<style></style>
