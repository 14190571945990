<template>
  <div
    class="block-group"
  >
    <div class="block-columns">

      <div v-if="imagePos === 'left'" class="block-column">
        <div class="block-group">
          <div v-if="$slots.image" class="block-image">
            <slot name="image"></slot>
          </div>
          <div v-else-if="imageSrc" class="block-image">
            <img v-lazy="imageSrc" alt="" />
          </div>
        </div>
      </div>

      <div
        class="block-column block-main-content"
        :class="[
          { 'no-image': imagePos === 'none' }
        ]"
      >
        <div class="block-col-stack">
          <div v-if="$slots.heading" class="block-heading">
            <slot name="heading"></slot>
          </div>
          <div v-if="$slots.title" class="block-title">
            <slot name="title"></slot>
          </div>
          <div v-if="$slots.desc" class="block-desc">
            <slot name="desc"></slot>
          </div>
          <div v-if="$slots.body" class="block-body">
            <slot name="body"></slot>
          </div>
          <div v-if="$slots.footer" class="block-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>

      <div v-if="imagePos === 'right'" class="block-column">
        <div class="block-group">
          <div v-if="$slots.image" class="block-image">
            <slot name="image"></slot>
          </div>
          <div v-else-if="imageSrc" class="block-image">
            <img v-lazy="imageSrc" alt="" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'block-with-image',
  props: {
    imagePos: String,
    imageSrc: String,
  }
};
</script>
<style></style>
